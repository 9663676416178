<template>
    <legal-information-page :info="getLEGAL_INFORMATION" @openFile="fileDownload" />
</template>

<script>
    import LegalInformationPage from "../../../components/pages/cabinet/about/LegalInformationPage";
    import {mapActions, mapGetters} from "vuex";
    export default {
        name: "LegalInformation",
        components: {LegalInformationPage},
        computed: {
            ...mapGetters("AboutModule", ["getLEGAL_INFORMATION"])
        },
        methods: {
            ...mapActions("regModule", ["DOWNLOAD_FILE"]),
            fileDownload({id, text_name, origin_name}){
                const index = origin_name.split('.').length -1
                this.DOWNLOAD_FILE({id: id, name: `${text_name}.${origin_name.split('.')[index]}`})
            }
        }
    }
</script>