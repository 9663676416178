<template>
    <div class="loaded-file" :style="width ? {'width': width} : ''">
        <div class="loaded-file__body" @click="$emit('openFile')">
            <div class="icon-clip">
                <v-icon size="medium" :hovering="false">paperclip</v-icon>
            </div>
            <div class="file-name">{{name}}</div>
        </div>
        <div v-if="hasDelete" :class="['icon-delete-file', {'disabled' : disabled}]">
            <v-icon :hovering="false" @click="del()">delete-file</v-icon>
        </div>
    </div>
</template>

<script>
    import VIcon from "../icons/VIcon";
    export default {
        name: "RwmFileLoaded",
        components: {VIcon},
        props: {
            filename: {
                type: String || undefined,
                required: true
            },
            disabled: {
                type: Boolean,
                default: false
            },
            hasDelete: {
                type: Boolean,
                default: true
            },
            width: {
                type: [Number, String, undefined],
                default: undefined
            },
            dontCut: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            name() {
                if (this.filename && !this.dontCut) {
                    let str = this.filename.split('.')
                    if (str[0].length > 9) {
                        str[0] = str[0].slice(0, 9) + "... "
                    }
                    return str.join('.')
                } else return this.filename
            }
        },
        methods: {
            del() {
                !this.disabled ? this.$emit('deleteFile') : ''
            }
        },
    }
</script>

<style lang="scss">
    .loaded-file {
        display: flex;
        align-items: center;
        width: 315px;
        height: 100%;
        color: $RWM-dark;
        &__body {
            display: flex;
            flex: 1 1 auto;
            align-items: flex-end;
            color: $RWM-grey;
            cursor: pointer;
            transition: all 300ms;
            &:hover {
                color: $RWM-red;
            }
        }
        & .icon-clip {
            margin-right: 12px;
        }
        & .file-name {
            font-style: normal;
            font-weight: normal;
            font-size: 21px;
            line-height: 29px;
            text-decoration-line: underline;
            flex: 1 1 auto;
        }
        & .icon-delete-file {
            cursor: pointer;
            &:hover {
                color: $RWM-red;
            }
            &.disabled {
                color: $RWM-grey-super-light
            }
        }
    }
</style>